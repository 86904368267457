/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import Drawer from "react-modern-drawer";
import { useForm, Controller } from "react-hook-form";
import "react-modern-drawer/dist/index.css";
import { toast } from "react-hot-toast";
import InputMask from "react-input-mask";
import data from "../../data/datajobs";
import {
  Container,
  Header,
  Title,
  AboutContainer,
  HeaderContainer,
  ActionsContainer,
  Separator,
  Jobname,
  FormContainer,
  Footer,
  FileInputContainer,
  ErrorMessage,
  DrawerContainer,
} from "./styles";
import logoImg from "../../assets/images/site/logo.png";
import ButtonComponent from "../../components/Global/Form/Button";
import vagasModel from "../../models/vagas.model";

export function PaginaDeVagas() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [resumeBase64, setResumeBase64] = useState("");
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  const location = useLocation();
  let trabalho = location.state?.job;
  if (!trabalho) {
    const { slug } = useParams();
    const trabalhoCorreto = data.find((item) => item.job.slug === slug);
    if (trabalhoCorreto) {
      trabalho = trabalhoCorreto;
    } else {
      console.log("Trabalho não encontrado para o slug fornecido na URL");
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();
  const [mask, setMask] = useState("(99) 9999-99999");

  const handleChange = (event) => {
    const value = event.target.value.replace(/\D/g, "");

    if (value.length > 10) {
      setMask("(99) 99999-9999");
    } else {
      setMask("(99) 9999-99999");
    }

    setValue("phone", event.target.value);
  };
  const onSubmit = async (formData) => {
    console.log("Formulário enviado:", formData);
    try {
      setIsSubmitting(true);
      formData.resume = resumeBase64;
      formData.jobTitle = trabalho.job.slug;
      formData.origin = "LP EasyDr.";

      const res = await vagasModel.apply(formData);
      if (res.status) {
        toast.success("Currículo enviado, aguarde nosso contato.", {
          duration: 10000,
        });
      } else {
        toast.error("Erro ao enviar currículo tente novamente mais tarde.");
      }
      setIsOpen(false);
      reset();
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Container>
        <Header>
          <HeaderContainer>
            <div className="logo">
              <a href="/">
                <img className="" src={logoImg} alt="Logo EasyDr." width={70} />
              </a>
            </div>
            <ActionsContainer>
              <button type="button" onClick={() => null}>
                <Link to="/#trabalhe-conosco" style={{ marginTop: "3px" }}>
                  <span>Voltar</span>
                </Link>

                <div className="arrow-wrapper">
                  <div className="arrow" />
                </div>
              </button>
            </ActionsContainer>
          </HeaderContainer>
        </Header>
        <div style={{ marginTop: "2em" }}>
          <Title>
            Trabalhe Conosco <Separator>| </Separator>{" "}
            <Jobname> {trabalho?.title}</Jobname>
          </Title>
        </div>
      </Container>
      <AboutContainer>
        <h1>A Easy Dr. Contabilidade Médica</h1>
        <p>
          Somos a primeira contabilidade médica fundada por médicos e contadores
          que se juntaram com um único propósito: liberar mais tempo pro médico
          se dedicar a sua carreira. Aqui na Easy, como gostamos de chamar,
          temos um cultura ágil com foco no cliente, estamos em constante
          mudança nos processos e serviços que prestamos pro nosso cliente,
          sempre com intuito de melhorar a experiência. Sabemos que a única
          forma de construir isso é juntando pessoas, processo e tecnologia,
          nessa ordem.
        </p>
        <hr style={{ marginTop: "2em", marginBottom: "2em" }} />
        <div className="job-details">
          <h3>Sobre a vaga de {trabalho?.title}</h3>
          {trabalho?.activities.map((activity, index) => {
            console.log({ activity });
            return (
              <React.Fragment key={index}>
                <h2>{activity.title}</h2>
                <ul>
                  {activity.tasks.map((task, taskIndex) => (
                    <li key={taskIndex}>{task}</li>
                  ))}
                </ul>
              </React.Fragment>
            );
          })}
        </div>
      </AboutContainer>
      <Footer>
        <ButtonComponent
          width={70}
          primary
          color="#025DFD"
          type="submit"
          onClick={toggleDrawer}
        >
          Quero me candidatar a vaga
        </ButtonComponent>
      </Footer>
      <DrawerContainer>
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="bottom"
          size="min-content"
        >
          <FormContainer>
            <h1>Venha fazer parte da nossa equipe!</h1>
            <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
              <div className="col-md-6">
                <label htmlFor="name" className="form-label">
                  Nome Completo
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  {...register("name", {
                    required: "Nome é obrigatório",
                    minLength: {
                      value: 3,
                      message: "O nome deve ter no mínimo 3 caracteres",
                    },
                    maxLength: {
                      value: 350,
                      message: "O nome deve ter no máximo 350 caracteres",
                    },
                  })}
                />
                {errors.name && (
                  <ErrorMessage>{errors.name.message}</ErrorMessage>
                )}
              </div>
              <div className="col-md-6">
                <label htmlFor="mail" className="form-label">
                  E-mail
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="mail"
                  {...register("mail", {
                    required: "E-mail é obrigatório",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Endereço de e-mail inválido",
                    },
                  })}
                />
                {errors.mail && (
                  <ErrorMessage>E-mail é obrigatório</ErrorMessage>
                )}
              </div>
              <div className="col-md-6">
                <label htmlFor="phone" className="form-label">
                  Telefone
                </label>
                <InputMask
                  mask={mask}
                  className="form-control"
                  id="phone"
                  {...register("phone", { required: true })}
                  onChange={handleChange}
                >
                  {(inputProps) => <input {...inputProps} />}
                </InputMask>
                {errors.phone && (
                  <ErrorMessage>Telefone é obrigatório</ErrorMessage>
                )}
              </div>
              <div className="col-md-6">
                <label htmlFor="como-conheceu" className="form-label">
                  Como conheceu a Easy Dr. Contabilidade Médica.
                </label>
                <select
                  className="form-select"
                  aria-label="Como conheceu a Easy Dr. Contabilidade Médica."
                  id="como-conheceu"
                  {...register("como_conheceu", { required: true })}
                >
                  <option value="">Selecione uma Opção</option>
                  <option value="indicacao">Indicação</option>
                  <option value="anuncio-no-google">Anúncio no Google</option>
                  <option value="redes-sociais">Redes Sociais</option>
                  <option value="parceiros">Parceiros</option>
                  <option value="eventos">Eventos</option>
                  <option value="outros">Outros</option>
                </select>
                {errors.como_conheceu && (
                  <ErrorMessage>Campo obrigatório</ErrorMessage>
                )}
              </div>
              <div>
                <FileInput
                  name="resume"
                  control={control}
                  acceptedFormats={[".pdf"]}
                  setResumeBase64={setResumeBase64}
                  required
                />
                {errors.resume && (
                  <ErrorMessage>
                    Por favor, escolha um arquivo PDF, DOCX ou texto.
                  </ErrorMessage>
                )}
              </div>
              <div className="mb-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="consentCheck"
                    {...register("consentCheck", { required: true })}
                  />
                  <label className="form-check-label" htmlFor="consentCheck">
                    Ao utilizar este formulário, você está consentindo com a
                    coleta e o processamento de suas informações por este site.
                  </label>
                  {errors.consentCheck && (
                    <ErrorMessage>
                      Você deve consentir com os termos
                    </ErrorMessage>
                  )}
                </div>
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-primary"
              >
                {isSubmitting ? "Enviando Currículo" : "Enviar Currículo"}
              </button>
            </form>
          </FormContainer>
        </Drawer>
      </DrawerContainer>
    </>
  );
}

const FileInput = ({
  name,
  control,
  acceptedFormats,
  setResumeBase64,
  ...rest
}) => {
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setResumeBase64(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <FileInputContainer>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field: { onChange } }) => (
          <div className="mb-3">
            <label htmlFor="resume" className="form-label">
              Anexe seu currículo atualizado
            </label>
            <input
              className="form-control"
              type="file"
              id="resume"
              accept={acceptedFormats.join(", ")}
              onChange={(e) => {
                handleFileChange(e);
                onChange(e.target.files[0]);
              }}
              {...rest}
            />
            <div id="emailHelp" className="form-text">
              Anexe seu currículo no formato .pdf
            </div>
          </div>
        )}
      />
    </FileInputContainer>
  );
};
