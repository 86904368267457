import styled from "styled-components";

export const Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    width: 100%;
    height: 100vh;
    margin: 0 auto;
`;

export const Titulo = styled.h1`
    margin: 2rem 0;
    font-size: 2rem;
    text-align: center;
`;

export const Subtitulo = styled.h2`
    margin: 0.5rem 0;
    font-size: 1.5rem;
    text-align: left;
    width: 100%;
`

export const Topicos = styled.ol`
    list-style-type: disc;
    text-align: left;
    width: 100%;
`
export const Topico = styled.li`
    margin-bottom: 1rem;
`;

export const Tabela = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1.5rem;
`

export const Cabecalho = styled.th`
    background-color: #191970;
    border: 1px solid black;
    padding: 8px;
    text-align: center;
    color: white;
`
export const Linha = styled.td`
  border: 1px solid black;
  padding: 8px;
  text-align: left;
  vertical-align: top;
`;

export const Link = styled.a`
  text-decoration: underline;
`