/* eslint-disable react/no-unescaped-entities */
import {
  Titulo,
  Div,
  Subtitulo,
  Topicos,
  Topico,
  Cabecalho,
  Tabela,
  Linha,
  Link,
} from "./styles";

export function politicaApp() {
  return (
    <Div>
      <Titulo>
        <strong>AVISO DE PRIVACIDADE</strong>
      </Titulo>
      <p>
        Bem vindo à <strong>EasyDr.!</strong> Fizemos este documento
        especialmente para você, pois nos preocupamos com sua privacidade e a
        proteção de seus dados pessoais. Aqui vamos explicar como e porquê
        utilizamos seus dados pessoais, quais são seus direitos e como você pode
        exercê-los.
      </p>
      <Subtitulo>
        <strong>1. PARA FACILITAR SUA LEITURA</strong>
      </Subtitulo>
      <p>
        Para que você consiga compreender melhor as informações que
        disponibilizamos aqui, gostaríamos de apresentar a definição de alguns
        termos que você poderá encontrar neste Aviso:
      </p>
      <Topicos>
        <Topico>
          <strong>Dado pessoal:</strong> É uma informação relacionada a uma
          pessoa física e que seja capaz de identificar a pessoa ou tornar
          possível a sua identificação. São exemplos de dados pessoais que podem
          permitir a sua identificação: Nome, CPF, telefone, e-mail, etc.
        </Topico>
        <Topico>
          <strong>Tratamento:</strong> É toda forma de uso que podemos fazer dos
          seus dados pessoais, incluindo, mas não se limitando às seguintes
          atividades: coleta, armazenamento, consulta, uso, compartilhamento,
          transmissão, classificação, reprodução, exclusão e avaliação.
        </Topico>
        <Topico>
          <strong>Controlador:</strong> Pessoa física ou jurídica, de direito
          público ou privado, a quem competem as decisões referentes ao
          tratamento de dados pessoais.
        </Topico>
        <Topico>
          <strong>Operador:</strong> Pessoa física ou jurídica, de direito
          público ou privado, que realiza o tratamento de dados pessoais em nome
          do controlador.
        </Topico>
        <Topico>
          <strong>Titular:</strong> É você, a pessoa física a quem os dados
          pessoais se referem.
        </Topico>
      </Topicos>
      <Subtitulo>
        <strong>2. QUAIS CATEGORIAS DE DADOS PESSOAIS A EASYDR TRATA?</strong>
      </Subtitulo>
      <p>
        Os dados pessoais que serão tratados vai depender de{" "}
        <strong>como você se relaciona conosco</strong>e
        <strong>qual a finalidade do tratamento</strong>. Nós coletamos apenas
        os dados que são realmente necessários para alcançar nossos objetivos e
        os utilizamos somente para as finalidades para as quais foram coletados.
      </p>
      <p>
        Listamos abaixoalgumas situações em que podemos tratar seus dados
        pessoais no contexto da sua relação com a <strong>EasyDr</strong>:
      </p>
      <Tabela>
        <thead>
          <tr>
            <Cabecalho>Finalidades de tratamento</Cabecalho>
            <Cabecalho>Categorias de Dados Pessoais Tratados</Cabecalho>
          </tr>
        </thead>
        <tbody>
          <tr>
            <Linha>
              <strong>
                1) Contato/trativas com interessados em nossos serviços:
              </strong>{" "}
              os dados são tratados para que possamos entrar em contato com
              você.
            </Linha>
            <Linha>Dados de identificação e de contato.</Linha>
          </tr>
          <tr>
            <Linha>
              <strong>2) Formalização de contrato com cliente:</strong> após a
              conclusão das negociações, os dados serão tratados para
              formalização de contratação.
            </Linha>
            <Linha>Dados de identificação.</Linha>
          </tr>
          <tr>
            <Linha>
              <strong>3) Cadastro de clientes em nossos sistemas:</strong> para
              a melhor execução das nossas obrigações contratuais, utilizamos
              sistemas onde cadastramos suas informações.
            </Linha>
            <Linha>Dados de identificação, de contato e de imóvel.</Linha>
          </tr>
          <tr>
            <Linha>
              <strong>4) Serviços contábeis:</strong> há uma diversidade de
              atividades que executamos onde são necessários vários dados
              pessoais, seus e de seus clientes e/ou funcionários. Muitas destas
              atividades de tratamento de dados pessoais são por conta de
              determinações legais.
            </Linha>
            <Linha>
              Dados de identificação; dados financeiros; dados de
              profissão/emprego de funcionários dos clientes.
            </Linha>
          </tr>
          <tr>
            <Linha>
              <strong>5) Suporte (SAC):</strong> os dados são tratados para
              identificar o usuário e atender suas solicitações.
            </Linha>
            <Linha>Dados de identificação e contato.</Linha>
          </tr>
          <tr>
            <Linha>
              <strong>6) Envio de informativos e publicidade:</strong> para
              envio de materiais informativos e informações sobre nossos
              serviços e novidades.
            </Linha>
            <Linha>Dados de identificação e contato.</Linha>
          </tr>
        </tbody>
      </Tabela>
      <Subtitulo>
        <strong>
          3. QUAL O PAPEL EXERCIDO PELA EASYDR NO TRATAMENTO DOS DADOS?
        </strong>
      </Subtitulo>
      <p style={{ textAlign: "left", width: "100%" }}>
        A depender da atividade de tratamento, a <strong>EasyDr</strong> poderá
        atuar como:
      </p>
      <Topicos>
        <Topico>
          <strong>Controladora</strong>, ocasião em que somos a responsável por
          tomar as principais decisões relativas ao tratamento de dados pessoais
          (ex: finalidade do tratamento, natureza dos dados tratados e duração
          do tratamento);
        </Topico>
        <Topico>
          <strong>Operadora</strong>, ocasião em que seguimos as decisões de um
          Controlador, podendo agir somente nos limites das finalidades
          estabelecidos por aquele, cabendo-nos apenas decisões não essenciais a
          respeito do tratamento de dados (ex: medidas técnicas).
        </Topico>
      </Topicos>
      <p>
        Para ficar claro: dentre as finalidades citadas no tópico anterior, nós
        somos a Controladora nas atividades de tratamento relacionadas às
        finalidades de n1, 2, 3 e 5. Já nas atividade de tratamento n4, somos
        Operadora de nossos clientes.
      </p>
      <Subtitulo>
        <strong>
          4. COM QUEM A EASYDR COMPARTILHA OS SEUS DADOS PESSOAIS?
        </strong>
      </Subtitulo>
      <p>
        Para conseguir disponibilizar todas nossas funcionalidades e oferecer o
        melhor serviço possível, podemos compartilhar e/ou fazer o uso
        compartilhado de seus dados com algumas organizações parceiras. Além
        disso, em determinadas situações, também temos o dever de transmitir
        algumas informações às autoridades públicas (notificações,
        fiscalizações, etc.), de acordo com o previsto em lei.
      </p>
      <p>
        Segue abaixo algumas situações em que podemos compartilhar e/ou usar de
        forma compartilhada os seus dados pessoais.
      </p>
      <Tabela style={{ backgroundColor: "#D3D3D3" }}>
        <tbody>
          <tr>
            <Linha>
              <strong>1) Armazenamento e processamento de dados:</strong> para
              fins de armazenamento e processamento de dados, utilizamos os
              sistemas listados abaixo.
              <div style={{ marginTop: "10px" }}>
                <span>
                  {" "}
                  a) Domínio (THOMSON REUTERS BRASIL CONTEÚDO E TECNOLOGIA LTDA,
                  CNPJ 00.910.509/0013-05);
                </span>
                <br />
                <span>
                  {" "}
                  b) Acessórias (VICP SOLUÇÕES LTDA-ME, CNPJ
                  28.495.096/0001-25);
                </span>
                <br />
                <span>
                  {" "}
                  c) Google Suite - GDrive (GOOGLE BRASIL INTERNET LTDA. , CNPJ
                  06.990.590/0001-23);
                </span>
              </div>
            </Linha>
          </tr>
          <tr>
            <Linha>
              <strong>2) Declarações em serviços contábeis</strong>: para
              prestação de nossos serviços, a exemplo das declarações,
              compartilhamos os dados necessários com{" "}
              <strong>órgão públicos</strong>, como a Receita Federal do Brasil,
              a Caixa Econômica Federal, Juntas Comerciais Estaduais e órgãos
              federais, estaduais/distritais e municipais, além de{" "}
              <strong>empresas privadas</strong>, como hospitais e clínicas, a
              critério do cliente.
            </Linha>
          </tr>
          <tr>
            <Linha>
              <strong>3) Publicidade direcionada</strong>: para divulgação de
              nossos serviços compartilhamos os dados recebidos em nossos
              formulários com nossa parceira comercial Moonflag Marketing (CNPJ
              39.414.220/0001-05), que gerenciará e utilizará as plataformas RD
              Station (CNPJ 13.021.784/0001-86) e MailChimp
              (https://www.intuit.com/privacy/statement/pt-br/) para publicidade
              direcionada.
            </Linha>
          </tr>
          <tr>
            <Linha>
              <strong>4) Consultoria ao vivo</strong>: para agendamento online
              utilizamos a plataforma Calendly
              (https://calendly.com/pt/security).
            </Linha>
          </tr>
        </tbody>
      </Tabela>
      <Subtitulo>
        <strong>
          5. QUAIS SÃO OS SEUS DIREITOS COMO TITULAR DE DADOS PESSOAIS?
        </strong>
      </Subtitulo>
      <p>
        Em atendimento ao que determina as normas de proteção de dados pessoas
        no Brasil, a <strong>EasyDR</strong> garante a todos a possibilidade de
        apresentação de solicitações baseadas nos direitos listados abaixo:
      </p>
      <Topicos>
        <Topico>
          <strong>Confirmação da existência de tratamento;</strong>
        </Topico>
        <Topico>
          <strong>Acesso aos dados;</strong>
        </Topico>
        <Topico>
          <strong>
            Correção de dados incompletos, inexatos ou desatualizados;
          </strong>
        </Topico>
        <Topico>
          <strong>
            Anonimização, bloqueio ou eliminação de dados desnecessários,
            excessivos ou tratados em desconformidade com o disposto nesta Lei;
          </strong>
        </Topico>
        <Topico>
          <strong>
            Portabilidade dos dados a outro fornecedor de serviço ou produto;
          </strong>
        </Topico>
        <Topico>
          <strong>
            Eliminação dos dados pessoais tratados com o consentimento do
            titular;
          </strong>
        </Topico>
        <Topico>
          <strong>
            Informação sobre entidades públicas e privadas com as quais
            compartilhamos seus dados;
          </strong>
        </Topico>
        <Topico>
          <strong>
            Informação sobre a possibilidade de não fornecer consentimento e
            sobre as consequências da negativa
          </strong>
        </Topico>
        <Topico>
          <strong>Revogação de consentimento</strong>
        </Topico>
        <Topico>
          <strong>
            Oposição a tratamento realizado com fundamento em uma das hipóteses
            de dispensa de consentimento, em caso de descumprimento ao disposto
            nesta Lei.
          </strong>
        </Topico>
      </Topicos>
      <p>
        Esses direitos poderão ser exercidos diretamente por você, ou por alguém
        que a represente mediante apresentação de procuração. Para requisitar um
        dos direitos listados acima, basta entrar em contato conosco pelo email
        privacidade@easydr.com.br. Para evitar fraudes, poderemos solicitar uma
        confirmação de identidade.
      </p>
      <p>
        É importante destacar que não será possível fazer a exclusão de dados
        pessoais que estejam relacionados à execução de contrato, à cumprimento
        de obrigação legal, ou outra base legal de tratamento de dados pessoais
        que não dependam de seu consentimento.
      </p>
      <p>
        <strong>
          IMPORTANTE: Nos casos em que atuamos apenas como Operadora, sua
          requisição deverá ser encaminhada diretamente ao Controlador
          responsável.
        </strong>
      </p>
      <Subtitulo>
        <strong>
          6. POR QUANTO TEMPO OS DADOS PESSOAIS SERÃO ARMAZENADOS?
        </strong>
      </Subtitulo>
      <p>
        Os seus dados pessoais serão armazenados somente pelo tempo que forem
        necessários para cumprir com as finalidades para as quais foram
        coletados, salvo se houver qualquer outra razão para sua manutenção
        como, por exemplo, cumprimento de quaisquer obrigações legais,
        regulatórias, contratuais, entre outras permitidas sob a lei.
      </p>
      <p>
        É importante informar que sempre fazemos uma análise técnica para
        determinar o período de retenção adequado para cada tipo de dado pessoal
        coletado, considerando a sua natureza, necessidade de coleta e
        finalidade para a qual ele será tratado, bem como eventuais necessidades
        de retenção para o cumprimento de obrigações ou o resguardo de direitos.
      </p>
      <p>
        A título de exemplo, os dados tratados para questões contratuais serão
        armazenados em nossa base enquanto nosso contrato estiver ativo, e por
        mais 5 anos após o encerramento para cumprimento de obrigações legais
        fiscais.
      </p>
      <Subtitulo>
        <strong>7. COMO PROTEGEMOS SEUS DADOS PESSOAIS?</strong>
      </Subtitulo>
      <p>
        Para garantir a sua privacidade e a proteção dos seus dados pessoais,
        adotamos recursos tecnológicos avançados visando a segurança de todos os
        dados tratados pela <strong>EasyDr</strong>. Entre as medidas de
        segurança aplicadas estão a implementação de controles de acesso a
        sistemas e barreiras contra o acesso indevido às bases de dados
        (incluindo firewalls), entre outros controles de segurança da
        informação.
      </p>
      <Subtitulo>
        <strong>8. COMO FALAR SOBRE DADOS PESSOAIS COM A EASYDR?</strong>
      </Subtitulo>
      <p>
        Se você acredita que seus dados pessoais foram tratados de maneira
        incompatível com este aviso ou com as suas escolhas enquanto Titular dos
        dados pessoais, ou, ainda, se você tiver dúvidas, comentários ou
        sugestões relacionadas a este aviso e à forma como tratamos seus dados
        pessoais, entre em contato conosco pelo email
        "privacidade@easydr.com.br".
      </p>
      <Subtitulo>
        <strong>9. A EASYDR UTILIZA COOKIES NO SITE?</strong>
      </Subtitulo>
      <p>
        Nós utilizamos cookies para melhorar o desempenho e a sua experiência
        como usuário e visitante do nosso site. Cookies são pequenos arquivos de
        texto que o nosso site, quando visitado, coloca no seu computador por
        meio do navegador de internet que você estiver utilizando (browser).
        Utilizamos três tipos de cookies, que passaremos a explicar um pouco
        melhor para você:
      </p>
      <Tabela>
        <thead>
          <tr>
            <Cabecalho style={{ whiteSpace: "nowrap" }}>
              Tipo de Cookies
            </Cabecalho>
            <Cabecalho>Descrição</Cabecalho>
          </tr>
        </thead>
        <tbody>
          <tr>
            <Linha>Cookies essenciais</Linha>
            <Linha>
              São essenciais para a utilização do site, permitindo funções
              básicas como a navegação e manter você logado em nossa plataforma.
              Sem eles, o nosso site não funcionará corretamente e os serviços
              não poderão ser fornecidos satisfatoriamente.
            </Linha>
          </tr>
          <tr>
            <Linha>Cookies estatísticos</Linha>
            <Linha>
              Servem para coletar informações de como você interage com nossa
              página. Mas não se preocupe, essas informações não permitem a sua
              identificação e são utilizadas com a finalidade de melhorar o
              funcionamento do nosso site.
            </Linha>
          </tr>
          <tr>
            <Linha>Cookies de marketing</Linha>
            <Linha>
              Esses cookies são usados para analisar o seu comportamento ao
              navegar em nosso site, e, após, exibir anúncios que possam ser
              mais relevantes para você. Eles também servem para medir a
              eficácia de um anúncio publicitário.
            </Linha>
          </tr>
        </tbody>
      </Tabela>
      <p>
        Alguns dos cookies que utilizamos são criados e administrados por
        empresas terceiras ("<strong>cookies de terceiros</strong>"), empresas
        estas que são fornecedoras de ferramentas e serviços que integramos à
        nossa página, tal como a plataforma de agendamento online Calendly, cuja
        configuração de cookies você poderá modificar ao acessar essa
        funcionalidade.
      </p>
      <p>
        Dentre outras informações que recebemos dessas empresas terceiras que
        colocam "cookies de terceiros" em nosso site podem estar incluídas, por
        exemplo, se é a primeira vez que você está visitando nosso site ou,
        ainda, informações sobre o seu comportamento de navegação para podermos
        otimizar as funcionalidades da nossa página. Para maiores detalhes a
        respeito dos "cookies de terceiros" você poderá acessar as Políticas de
        Privacidade e/ou de Cookies de cada um de nossos fornecedores clicando
        nos links abaixo:
      </p>
      <Topicos>
        <Topico>
          <Link href="https://policies.google.com/technologies/cookies?hl=pt-BR">
            Google
          </Link>
          ;
        </Topico>
        <Topico>
          <Link href="https://legal.rdstation.com/pt/privacy-policy/">
            RD Station
          </Link>
          ;
        </Topico>
        <Topico>
          <Link href="https://calendly.com/legal/privacy-notice">Calendly</Link>
          ;
        </Topico>
        <Topico>
          <Link href="https://conteudos.acessorias.com/politica-de-privacidade">
            Acessórias
          </Link>
          .
        </Topico>
      </Topicos>
      <p>
        A qualquer momento você pode, por meio do seu navegador, decidir sobre o
        bloqueio do uso de cookies em seu sistema. Neste caso, o bloqueio pode
        resultar na impossibilidade de ter acesso a algumas funcionalidades do
        site.
      </p>
      <p>
        Caso você queira desabilitar os cookies, siga as instruções do seu
        navegador. Se você utiliza algum dos navegadores abaixo, basta clicar no
        link correspondente para mais informações:
      </p>
      <Topicos>
        <Topico>
          Caso você utilize o{" "}
          <Link href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US">
            Firefox
          </Link>
          ;
        </Topico>
        <Topico>
          Caso você utilize o{" "}
          <Link href="https://support.microsoft.com/pt-br/windows/gerenciar-cookies-no-microsoft-edge-exibir-permitir-bloquear-excluir-e-usar-168dab11-0753-043d-7c16-ede5947fc64d">
            Internet Explorer
          </Link>
          ;
        </Topico>
        <Topico>
          Caso você utilize o{" "}
          <Link href="https://support.google.com/chrome/answer/95647?hl=en">
            Google Chrome
          </Link>
          ;
        </Topico>
        <Topico>
          Caso você utilize o{" "}
          <Link href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac">
            Safari
          </Link>
          .
        </Topico>
      </Topicos>
      <Subtitulo>
        <strong>10. MUDANÇAS NO AVISO DE PRIVACIDADE</strong>
      </Subtitulo>
      <p>
        Buscando melhorar os nossos serviços e a forma como operamos, este Aviso
        de Privacidade por passar por atualizações para refletir as melhorias
        realizadas. Desta forma, recomendamos a visita periódica desta página
        para que você tenha conhecimento sobre as modificações efetivadas.
      </p>
      <p style={{ textAlign: "center", marginTop: "20px" }}>
        <strong>DATA DA ÚLTIMA ATUALIZAÇÃO:</strong> 04 de abril de 2022.
      </p>
      <p>Versão 02</p>
      <p style={{ textAlign: "center", marginTop: "-20px" }}>
        <strong>Histórico de versões:</strong>
      </p>
      <p style={{ textAlign: "center", marginTop: "-20px" }}>
        Versão 01 - 20 de dezembro de 2021.
      </p>
    </Div>
  );
}
