/* eslint-disable camelcase */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import robot from "../services/robot";
import nexus from "../services/nexus";

async function alive() {
  try {
    const { data } = await robot.get("/robot-ecac/status");
    console.log(data);
    return data;
  } catch (e) {
    return {
      status: false,
      total: 0,
      cache: 0,
      active: false,
      msg: "",
    };
  }
}

async function init(form) {
  try {
    const { data } = await robot.post("/robot-ecac/start", form);
    return data;
  } catch (e) {
    return false;
  }
}

// eslint-disable-next-line camelcase
async function get_columns(form) {
  try {
    const { data } = await nexus.post("/all/columns", form);
    return data;
  } catch (e) {
    console.log(e);
    return [];
  }
}

// eslint-disable-next-line camelcase
async function get_reporst_zip(form) {
  try {
    const response = await nexus.post("/robot-ecac/report-zip", form, {
      responseType: "blob", // Define o tipo de resposta como blob (binary data)
    });

    // Cria um link temporário para fazer o download do arquivo
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "guias_analista.zip");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (e) {
    return false;
  }
}

async function stop() {
  try {
    const { data } = await robot.get("/robot-ecac/stop");
    return true;
  } catch (e) {
    return false;
  }
}

async function cleanCache() {
  try {
    const { data } = await robot.get("/robot-ecac/clean-cache");
    return true;
  } catch (e) {
    return false;
  }
}

async function report() {
  try {
    const response = await robot.get("/robot-ecac/report", {
      responseType: "blob", // Define o tipo de resposta como blob (binary data)
    });
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", "guias.zip");
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (e) {
    return false;
  }
}

async function init_situacao_fiscal(form) {
  try {
    const { data } = await robot.post(
      "/robot-ecac/situacao-fiscal/start",
      form
    );
    return data;
  } catch (e) {
    return false;
  }
}

async function robot_list_sit_fiscal() {
  try {
    const { data } = await robot.get("/robot-ecac/situacao-fiscal/listar");
    return data;
  } catch (e) {
    return [];
  }
}

async function clear_cache() {
  try {
    const { data } = await robot.get("/robot-ecac/situacao-fiscal/clan-cache");
    return data;
  } catch (e) {
    return false;
  }
}

async function sit_fiscal_download(name) {
  try {
    const response = await robot.get(
      `/robot-ecac/situacao-fiscal/download/${name}`,
      {
        responseType: "arraybuffer", // Define o tipo de resposta como arraybuffer (dados binários)
      }
    );

    // Cria um link temporário para fazer o download do arquivo
    const blob = new Blob([response.data], { type: "application/zip" }); // Especifique o tipo MIME como application/zip
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (e) {
    console.log(e);
    return false;
  }
}

async function simples_nascional_init(form) {
  try {
    const { data } = await robot.post("/simps_nacio/init", form);
    return data;
  } catch (e) {
    console.log(e);
    return [];
  }
}

async function list_result_das() {
  try {
    const { data } = await robot.get("/simps_nacio/list-all");
    return data;
  } catch (e) {
    return [];
  }
}

async function download_guia_das(name) {
  try {
    const response = await robot.get(`/simps_nacio/download/${name}`, {
      responseType: "arraybuffer", // Define o tipo de resposta como arraybuffer (dados binários)
    });

    // Cria um link temporário para fazer o download do arquivo
    const blob = new Blob([response.data], { type: "application/zip" }); // Especifique o tipo MIME como application/zip
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (e) {
    console.log(e);
    return false;
  }
}

async function simular_simples_nacional(form) {
  try {
    const { data } = await robot.post("/simps_nacio/simular", form);
    return data;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export default {
  alive,
  stop,
  report,
  init_situacao_fiscal,
  init,
  cleanCache,
  get_columns,
  robot_list_sit_fiscal,
  clear_cache,
  simular_simples_nacional,
  get_reporst_zip,
  sit_fiscal_download,
  simples_nascional_init,
  list_result_das,
  download_guia_das,
};
