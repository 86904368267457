import React, { useState } from "react";

import { Button, Form } from "react-bootstrap";

import nexus from "../../../services/nexus";

const CorretorOfx = () => {
  const [csvFile, setCsvFile] = useState(null);
  const [ofxFile, setOfxFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (event) => {
    setCsvFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append("csv_file", csvFile);

    try {
      const response = await nexus.post("/convert_to_ofx", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "blob", // Define o tipo de resposta como blob (para arquivos)
      });

      // Cria um objeto URL para o arquivo OFX e atribui ao estado
      const url = window.URL.createObjectURL(new Blob([response.data]));
      setOfxFile(url);
      console.log(ofxFile);

      // Inicia automaticamente o download do arquivo OFX
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "extrato.ofx");
      link.click();
    } catch (error) {
      console.error("Erro ao enviar requisição:", error);
    }

    setIsLoading(false);
  };

  return (
    <div>
      <h5>Corretor de OFX</h5>
      <span>Adicione um arquivo OFX corrompido e baixe um atualizado.</span>
      <br />
      <br />
      <form onSubmit={handleSubmit}>
        <Form.Control type="file" onChange={handleFileChange} />
        <br />
        <Button type="submit" disabled={!csvFile || isLoading}>
          {isLoading ? "Enviando..." : "Enviar"}
        </Button>
      </form>
    </div>
  );
};

export default CorretorOfx;
