import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import nexus2 from "../../../services/nexus2";

const FormularioNFe = () => {
  const [naturezaOperacaoOptions, setNaturezaOperacaoOptions] = useState([]);
  const [load, setLoad] = useState(false);
  const [situacaoTributariaOptions, setSituacaoTributariaOptions] = useState(
    []
  );
  const [formData, setFormData] = useState({
    natureza_operacao: "1",
    situacao_tributaria: "N",
    cpf_cnpj: "",
    nome_razao_social: "",
    cep: "",
    servico: {
      municipio: null,
      discriminacao: "",
      valor: 0,
      quantidade: 1,
      desc_condicionado: 0,
      desc_incondicionado: 0,
      deducoes: 0,
    },
  });

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const naturezaOperacaoResponse = await nexus2.get(
          "/nfe/natureza-operacao"
        );
        setNaturezaOperacaoOptions(naturezaOperacaoResponse.data);

        const situacaoTributariaResponse = await nexus2.get(
          "/nfe/situacao-tributaria"
        );
        setSituacaoTributariaOptions(situacaoTributariaResponse.data);
      } catch (error) {
        console.error("Erro ao carregar opções:", error);
      }
    };

    fetchOptions();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleServicoInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      servico: { ...prevData.servico, [name]: value },
    }));
  };

  const handleSubmit = async (e) => {
    setLoad(true);
    try {
      e.preventDefault();
      const { data } = await nexus2.post("/nfe/start", formData);
      console.log(data);
      window.location.href = "/app/nf-e/list";
    } catch (error) {
      console.log(error);
    }
    setLoad(false);
  };

  return (
    <Container>
      <div className="mt-4 mb-4">
        <h5>Emitir Nota Fiscal:</h5>
      </div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Group controlId="natureza_operacao">
              <Form.Label>Natureza de Operação*</Form.Label>
              <Form.Control
                as="select"
                required
                name="natureza_operacao"
                value={formData.natureza_operacao}
                onChange={handleInputChange}
              >
                <option value="">Selecione...</option>
                {naturezaOperacaoOptions.map((option) => (
                  <option key={option.key} value={option.key}>
                    {option.key} - {option.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="situacao_tributaria">
              <Form.Label>Situação Tributária*</Form.Label>
              <Form.Control
                as="select"
                required
                name="situacao_tributaria"
                value={formData.situacao_tributaria}
                onChange={handleInputChange}
              >
                <option value="">Selecione...</option>
                {situacaoTributariaOptions.map((option) => (
                  <option key={option.key} value={option.key}>
                    {option.key} - {option.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Form.Group controlId="cpf_cnpj" className="mt-2">
          <Form.Label>CPF/CNPJ*</Form.Label>
          <Form.Control
            type="text"
            name="cpf_cnpj"
            required
            value={formData.cpf_cnpj}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group controlId="nome_razao_social" className="mt-2">
          <Form.Label>Nome/Razão Social*</Form.Label>
          <Form.Control
            type="text"
            name="nome_razao_social"
            value={formData.nome_razao_social}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group controlId="cep" className="mt-2">
          <Form.Label>CEP*</Form.Label>
          <Form.Control
            type="text"
            required
            name="cep"
            value={formData.cep}
            onChange={handleInputChange}
          />
        </Form.Group>
        <div className="mt-4 mb-4">
          <h5>Serviços:</h5>
        </div>

        <Form.Group controlId="servico_municipio" style={{ marginBottom: 15 }}>
          <Form.Label>Cod. Município</Form.Label>

          <Form.Control
            type="number"
            name="municipio"
            value={formData.servico.municipio}
            onChange={handleServicoInputChange}
          />
          <span style={{ fontSize: 11 }}>
            (caso deixe em branco, ele utiliza o municipio que a empresa
            reside.)
          </span>
        </Form.Group>

        <Form.Group controlId="servico_discriminacao">
          <Form.Label>Discriminação*</Form.Label>
          <Form.Control
            as="textarea"
            required
            rows={3}
            name="discriminacao"
            value={formData.servico.discriminacao}
            onChange={handleServicoInputChange}
          />
        </Form.Group>
        <br />
        <Row>
          <Col>
            <Form.Group controlId="servico_valor">
              <Form.Label>Valor*</Form.Label>
              <Form.Control
                type="number"
                required
                name="valor"
                value={formData.servico.valor}
                onChange={handleServicoInputChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="servico_quantidade">
              <Form.Label>Quantidade*</Form.Label>
              <Form.Control
                type="number"
                name="quantidade"
                required
                value={formData.servico.quantidade}
                onChange={handleServicoInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Form.Group controlId="servico_desc_condicionado">
              <Form.Label>Desconto Condicionado</Form.Label>
              <Form.Control
                type="number"
                name="desc_condicionado"
                value={formData.servico.desc_condicionado}
                onChange={handleServicoInputChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="servico_desc_incondicionado">
              <Form.Label>Desconto Incondicionado</Form.Label>
              <Form.Control
                type="number"
                name="desc_incondicionado"
                value={formData.servico.desc_incondicionado}
                onChange={handleServicoInputChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="servico_deducoes">
              <Form.Label>Deduções</Form.Label>
              <Form.Control
                type="number"
                name="deducoes"
                value={formData.servico.deducoes}
                onChange={handleServicoInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Button
          variant="primary"
          disabled={load}
          className="mt-4"
          type="submit"
        >
          Enviar
        </Button>
      </Form>
    </Container>
  );
};

export default FormularioNFe;
