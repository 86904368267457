/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { useEffect, useState } from "react";
import { Button, Col, Form, ListGroup, Row, Spinner } from "react-bootstrap";
import { FiDownload } from "react-icons/fi";
import utils from "../../../../helpers/utils";
import robotecacMode from "../../../../models/robotecac.mode";

export default function SituacaoFiscal() {
  const [form, setForm] = useState({});
  const [loading, setLogin] = useState(false);
  const [config, setConfigs] = useState({});
  const [results, setResults] = useState([]);

  async function init() {
    const data = await robotecacMode.alive();
    const res = await robotecacMode.robot_list_sit_fiscal();
    setConfigs(data);
    setResults(res);
  }

  async function handler_reset() {
    init();
    setInterval(() => {
      init();
    }, 20000);
  }
  useEffect(() => {
    handler_reset();
  }, []);

  async function setFile(target) {
    const file = await utils.GetBase64(target.files[0]);
    const smf = { ...form, disabled: false, file: file.split(",")[1] };
    setForm(smf);
  }

  const handleFileChange = async (event) => {
    await setFile(event.target);
  };

  async function submit() {
    setLogin(true);
    const { data } = await robotecacMode.init_situacao_fiscal(form);
    setLogin(false);
  }

  async function download(name) {
    await robotecacMode.sit_fiscal_download(name);
  }

  async function clear_cache() {
    setLogin(true);
    await robotecacMode.clear_cache();
    setLogin(false);
  }

  return (
    <>
      <h5>Situação Fiscal</h5>
      <br />

      <Row>
        <Col>
          <Form.Label htmlFor="inputPassword5">
            Planilha do Colaborador:
          </Form.Label>
          <Form.Control
            type="file"
            accept=".xlsx"
            onChange={(e) => handleFileChange(e)}
          />
          <br />
          {config?.total ? (
            <Row>
              <span>Robô: {config?.active ? "Ativo" : "Inativo"}</span>
              <span>Status: {config?.msg ?? "-"}</span>
            </Row>
          ) : null}

          <br />
        </Col>
      </Row>

      <Row>
        <Col md={2}>
          <Button
            className="login-btn"
            primary
            color="#025DFD"
            disabled={config?.active}
            onClick={() => submit()}
          >
            {loading ? (
              <Spinner size="sm" animation="border" role="status" />
            ) : null}
            Iniciar
          </Button>
        </Col>
        <Col md={2}>
          <Button
            className="login-btn"
            primary
            color="#025DFD"
            disabled={config?.active}
            onClick={() => clear_cache()}
          >
            {loading ? (
              <Spinner size="sm" animation="border" role="status" />
            ) : null}
            Limpar Cache
          </Button>
        </Col>
      </Row>

      <br />
      <br />

      {results.length > 0 ? <h6>Resultados:</h6> : null}
      <ListGroup>
        {results.map((e) => (
          <ListGroup.Item>
            <a
              onClick={() => download(e.name)}
              style={{ flexDirection: "row", cursor: "pointer" }}
            >
              <FiDownload style={{ marginRight: 5 }} /> {e.name}
            </a>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </>
  );
}
