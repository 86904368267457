import { useEffect } from "react";
import { Link } from "react-router-dom";
import { BsCalculator } from "react-icons/bs";
import { CalcButton, Container } from "./styles";
import { useNavbarScroll } from "../../../hooks/useNavbarScroll";

export default function Button() {
  const { isVisible, setisVisible } = useNavbarScroll();
  function handleScroll() {
    if (window.scrollY >= 150) {
      setisVisible(true);
      return;
    }
    setisVisible(false);
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  return (
    <Container isVisible={isVisible}>
      {sessionStorage.getItem("coins-calculadora") > 0 && (
        <Link to="/simular">
          <CalcButton>
            <BsCalculator />
          </CalcButton>
        </Link>
      )}
      {sessionStorage.getItem("coins-calculadora") <= 0 && (
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        <a href="https://marketing.easydr.com.br/simulador-de-impostos-easy-dr">
          <CalcButton>
            <BsCalculator />
          </CalcButton>
        </a>
      )}
    </Container>
  );
}
