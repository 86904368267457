/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
import React from "react";
import { Table } from "react-bootstrap";

const FaturamentoGrid = ({ data }) => {
  console.log(data);
  if (!data || data.length === 0) {
    return null;
  }
  const columns = Object.keys(data[0]);

  return (
    <Table striped bordered hover className="table table-auto">
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th
              style={{
                minWidth:
                  column === "nome_empresa" ||
                  column === "nome_cliente" ||
                  column === "emissao" ||
                  column === "cnpj_cliente" ||
                  column === "periodo"
                    ? 300
                    : 150,
              }}
              key={index}
            >
              {column}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {columns?.map((column, colIndex) => (
              <td key={colIndex}>{row[column]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default FaturamentoGrid;
