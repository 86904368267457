const data = [
  {
    id: 1,
    active: false,
    title: "Auxiliar de Legalização",
    subTitle: "Vaga presencial, CLT, 44h semanais",
    job: {
      title: "Auxiliar de Legalização",
      slug: "auxiliar-de-legalizacao",
      about:
        "Para participar do processo favor preencher o formulário: https://forms.office.com/r/5eH0zu8tzM",
      activities: [
        {
          title: "PROCESSOS GERAIS",
          tasks: [
            "Realizar atendimento ao cliente de forma personalizada",
            "Realizar abertura de empresas, baixas, alterações contratuais, cadastros e licenciamentos junto aos órgãos públicos",
            "Contato telefônico ativo junto aos municípios para coleta de dados, solicitação de arquivos, aprovações, etc",
            "Orientar os clientes sobre os processos de abertura, alterações e baixas, e outras dúvidas relacionadas a PJ em geral",
            "Solicitar, renovar e sanar dúvidas relacionadas a certificados digitais",
            "Realizar agendamentos em orgãos publicos, como: Prefeituras, juntas comerciais, e demais locais",
            "Emitir certidões, processos, protocolos e alvarás",
            "Realizar cadastramento da empresa em órgãos públicos e programas internos da Easydr",
            "Auxiliar nas demandas de pessoa física que facilitam o processo da abertura da Pj, por exemplo: Auxiliar no cadastramento da conta gov, auxiliar na solicitação de aumento de nível, auxiliar no preenchimento de dados pessoais e etc",
            "Analisar e orientar os clientes quanto á licitações e estatutos",
            "Acompanhar processos que demandam prazos como: Simples Nacional, acesso de nota fiscal, andamento de protocolos municipais, dentre outros",
            "Cadastrar e controlar as tarefas anuais como: Taxa municipais, vigilância sanitária, meio ambiente, dentre outras",
            "Realizar a emissão de boletos e guias municipais e estaduais",
          ],
        },
        {
          title: "ATENDIMENTO AO CLIENTE",
          tasks: [
            "Realizar atendimento ao cliente de forma personalizada",
            "Realizar abertura de empresas, baixas, alterações contratuais, cadastros e licenciamentos junto aos órgãos públicos",
            "Contato telefônico ativo junto aos municípios para coleta de dados, solicitação de arquivos, aprovações, etc",
            "Orientar os clientes sobre os processos de abertura, alterações e baixas, e outras dúvidas relacionadas a PJ em geral",
            "Solicitar, renovar e sanar dúvidas relacionadas a certificados digitais",
            "Realizar agendamentos em orgãos publicos, como: Prefeituras, juntas comerciais, e demais locais",
            "Emitir certidões, processos, protocolos e alvarás",
            "Realizar cadastramento da empresa em órgãos públicos e programas internos da Easydr",
            "Auxiliar nas demandas de pessoa física que facilitam o processo da abertura da Pj, por exemplo: Auxiliar no cadastramento da conta gov, auxiliar na solicitação de aumento de nível, auxiliar no preenchimento de dados pessoais e etc",
            "Analisar e orientar os clientes quanto á licitações e estatutos",
            "Acompanhar processos que demandam prazos como: Simples Nacional, acesso de nota fiscal, andamento de protocolos municipais, dentre outros",
            "Cadastrar e controlar as tarefas anuais como: Taxa municipais, vigilância sanitária, meio ambiente, dentre outras",
            "Realizar a emissão de boletos e guias municipais e estaduais",
          ],
        },
        {
          title: "FINANCEIRO ",
          tasks: [
            "Cobrar os clientes sobre taxas vencidas ou prestes á vencer (Taxa municipais, estaduais ou guias de certificados digitais).",
          ],
        },
        {
          title: "ADMINISTRATIVO ",
          tasks: [
            "Utilização de programas internos como: Acessórias, e-mail, trello, whatsapp, excel e drive;",
            "Utilização de mensagens e manuais padrões internos para uma boa conduta profissional.",
          ],
        },
        {
          title: "QUALIFICAÇÕES ",
          tasks: [
            "Excelente comunicação verbal e escrita",
            "Fortes habilidades organizacionais, atenção e foco",
            "Capacidade de lidar com um alto fluxo de informações, demandas e cobranças",
            "Paciência e eficácia na resolução de conflitos, tanto internos quanto externos",
          ],
        },
        {
          title: "DESEJÁVEL",
          tasks: [
            "Experiência anterior com atendimento ao cliente, seja administrativa, abordagem ou telemarketing",
            "Experiência com órgãos legisladores ou órgãos públicos em geral",
            "Conhecimento sobre legalização",
          ],
        },
        {
          title: "PAGAMENTO E HORÁRIO DE TRABALHO: ",
          tasks: [
            "Vaga presencial, CLT, 44h semanais (segunda a sexta 08:00 às 11:48 e 13:00 às 18:00 horas).",
            "Vale refeição R$ 400/mês (cartão Caju).",
            "Vale Transporte (Pode ser flexibilizado por vale combustível Caju).",
            "Plano de saúde (UNIMED).",
            "Salário Fixo: Salário compatível com o mercado.",
            "Salário variável: Anual: PLR (participação nos lucros)",
            "Dia do aniversário: Day use (dia de folga)",
          ],
        },
      ],
    },
  },
  {
    id: 2,
    active: false,
    title: "Auxiliar de contabilidade",
    subTitle: "Vaga presencial, CLT, 44h semanais",
    job: {
      title: "Auxiliar de contabilidade",
      slug: "auxiliar-de-contabilidade",
      about:
        "Para participar do processo é OBRIGATÓRIO preencher o formulário por favor: https://forms.office.com/r/v0qXgjWPqL",
      activities: [
        {
          title: "PROCESSOS GERAIS",
          tasks: [
            "Preencher planilhas de controle mensal para auxílio na apuração;",
            "Emitir documentos fiscais periódicos: notas fiscais de serviço, certidões negativas, guias para pagamento de impostos e boletos para utilização de portais emissores;",
            "Apurar os tributos relacionados a folha de pagamento, Simples Nacional e alíquota de ISS;",
            "Escrituração de documentos fiscais e extratos bancários;",
            "Encerrar Livros Fiscais;",
            "Lançar recibos de outros vínculos para cálculo do INSS;",
            "Verificar a situação fiscal das empresas, enquadramento do regime tributário nos portais municipais;",
            "Optar anualmente pelo regime de competência das empresas;",
            "Detalhar impostos para empresas com sócios;",
            "Entregar obrigações acessórias para empresas em processo de baixa.",
          ],
        },
        {
          title: "ADMINISTRATIVO E FINANCEIRO",
          tasks: [
            "Acessar os portais disponibilizados pelos clientes para verificar a existência de notas para emissão;",
            "Registrar empresa junto ao Conselho Estadual de Medicina e credenciar junto as empresas tomadoras de serviço;",
            "Enviar guias de pagamento de impostos, notas fiscais, honorários, documentação para credenciamento e registro junto aos Conselhos de Medicina Estaduais;",
            "Renovar anualmente registro do Conselho Estadual de Medicina e credenciamento junto aos tomadores de serviço;",
            "Acompanhar tarefas através do Software Acessórias;",
            "Solicitar extratos bancários e declarações de residência médica;",
            "Realizar o BPO financeiro;",
            "Analisar Estatutos/Editais e se necessário realizar o encaminhamento para nossa advogada parceira.",
          ],
        },
        {
          title: "ATENDIMENTO ",
          tasks: [
            "Atendimento via WhatsApp, contato telefônico, e-mail e chamadas de vídeo.",
            "Contato ativo com Órgãos Públicos.",
          ],
        },
        {
          title: "QUALIFICAÇÕES",
          tasks: [
            "Ter formação ou estar cursando Ciências Contábeis, Administração ou áreas afins",
            "Excelente comunicação verbal e escrita.",
            "Saber lidar com alto fluxo de informações, demandas e cobranças.",
            "Paciência e eficácia para resolver situações de conflito interno e externo",
          ],
        },
        {
          title: "DESEJÁVEL ",
          tasks: [
            "Experiência em atendimento ao cliente, rotinas contábeis ou afinidade com serviços administrativos.",
          ],
        },
        {
          title: "PAGAMENTO E HORÁRIO DE TRABALHO: ",
          tasks: [
            "Vaga presencial, CLT, 44h semanais (segunda a sexta 08:00 às 11:48 e 13:00 às 18:00 horas).",
            "Vale refeição R$ 400/mês (cartão Caju).",
            "Plano de saúde (Unimed).",
            "Vale Transporte (Pode ser flexibilizado por vale combustível Caju).",
            "Salário Fixo: Salário compatível com o mercado.",
            "Salário Variável: Mensal: variável por quantidade de clientes na carteira.",
            "Salário Variável: Anual: PLR (participação nos lucros).",
            "Dia do aniversário: Day use (dia de folga).",
          ],
        },
      ],
    },
  },
  {
    id: 3,
    active: true,
    title: "Analista Fiscal Jr.",
    subTitle: "Vaga presencial, CLT, 44h semanais",
    job: {
      title: "Analista Fiscal Jr.",
      slug: "analista-fiscal-jr",
      about:
        "Somos a EasyDr. Contabilidade e Finanças Médicas e estamos em busca de um Analista Fiscal Jr. Se você se identifica ou conhece alguém que possa indicar, confira as informações abaixo e envie seu currículo para rh@easydr.com.br",
      activities: [
        {
          title: "Escopo das atividades",
          tasks: [
            "Realizar atendimento ao cliente de forma personalizada",
            "Realizar emissões de notas fiscais, apuração de impostos, cálculo de folha de pagamento Pró-Labore, envio de declarações e lançamentos de extratos",
            "Emitir documentos fiscais periódicos, notas fiscais de serviço certidões negativas, guias para pagamento de impostos e boletos para utilização de portais emissores",
            "Registrar empresa junto ao Conselho Estadual de Medicina e credenciar junto aos tomadores de serviço",
            "Solicitar extratos e declarações de residência médica",
            "Acessar os portais disponibilizados pelos clientes para verificar a existência de notas para emissão",
            "Verificar a situação fiscal das empresas, enquadramento do regime tributário nos portais municipais",
            "Detalhar impostos para empresas com sócios, dentre outras funções pertinentes ao cargo",
          ],
        },
        {
          title: "Requisitos",
          tasks: [
            "Graduação em Ciências Contábeis - Experiência de no mínimo 2 anos na função.",
          ],
        },
        {
          title: "Competências e Habilidades necessárias",
          tasks: [
            "Boa comunicação verbal e escrita",
            "Desejável vivência em rotina de escritório",
            "Organização, atenção, foco e pró atividade",
            "Habilidade de trabalhar em ambiente colaborativo",
            "Capacidade de resolução de problemas",
          ],
        },
        {
          title: "PAGAMENTO E HORÁRIO DE TRABALHO:",
          tasks: [
            "Vaga presencial, CLT, 44h semanais (segunda a sexta 08:00 às 11:48 e 13:00 às 18:00 horas).",
            "Vale refeição R$ 400/mês (Cartão Caju).",
            "Plano de saúde (Unimed).",
            "Vale transporte (Pode ser flexibilizado por vale combustível Caju).",
            "Salário Fixo: Salário compatível com o mercado.",
            "Salário Variável: Anual: PLR (Participação nos lucros).",
            "Dia do aniversário: Day Use (dia de folga).",
          ],
        },
        {
          title: "Local",
          tasks: [
            "Trabalho presencial. Empresa localizada no Centro de Juiz de Fora.",
          ],
        },
      ],
    },
  },
  {
    id: 4,
    active: true,
    title: "Auxiliar Administrativo",
    subTitle: "Vaga presencial, CLT, 44h semanais",
    job: {
      title: "Auxiliar Administrativo",
      slug: "auxiliar-administrativo",
      about:
        "Para participar do processo favor preencher o formulário: https://forms.office.com/r/5eH0zu8tzM",
      activities: [
        {
          title: "PROCESSOS GERAIS",
          tasks: [
            "Preencher planilhas de controle",
            "Emitir documentos, notas fiscais de serviço, certidões negativas, preenchimento e livros fiscais",
            "Auxiliar nos lançamentos contábeis",
            "Encerrar Livros Fiscais",
            "Providenciar toda documentação para credenciamento dos médicos junto aos hospitais e clinicas",
          ],
        },
        {
          title: "ADMINISTRATIVO E FINANCEIRO",
          tasks: [
            "Ler e interpretar editais de credenciamento em hospitais e clinicas",
            "Providenciar a documentação necessária para o credenciamento",
            "Analisar Estatutos/Editais e se necessário realizar o encaminhamento para nossa advogada parceira",
          ],
        },
        {
          title: "ATENDIMENTO",
          tasks: [
            "Atendimento via Digisac, contato telefônico, e-mail e chamadas de vídeo",
          ]
        },
        {
          title: "QUALIFICAÇÕES ",
          tasks: [
            "Ter formação ou estar cursando Ciências Contábeis, Administração ou áreas afins",
            "Excelente comunicação verbal e escrita, Organização, atenção e foco",
            "Saber lidar com alto fluxo de informações, demandas e cobranças",
            "Paciência e eficácia para resolver situações de conflito interno e externo",
          ],
        },
        {
          title: "DESEJÁVEL",
          tasks: [
            "Experiência anterior com atendimento ao cliente, seja administrativa, abordagem ou telemarketing",
          ],
        },
        {
          title: "PAGAMENTO E HORÁRIO DE TRABALHO: ",
          tasks: [
            "Vaga presencial, CLT, 44h semanais (segunda a sexta 08:00 às 11:48 e 13:00 às 18:00 horas).",
            "Vale refeição R$ 400/mês (cartão Caju).",
            "Vale Transporte (Pode ser flexibilizado por vale combustível Caju).",
            "Plano de saúde (UNIMED).",
            "Salário Fixo: Salário compatível com o mercado.",
            "Salário variável: Anual: PLR (participação nos lucros)",
            "Dia do aniversário: Day use (dia de folga)",
          ],
        },
        {
          title: "Local",
          tasks: [
            "Trabalho presencial. Empresa localizada no Centro de Juiz de Fora.",
          ],
        },
      ],
    },
  },
];

export default data;
