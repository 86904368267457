/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";
import { Col, Form, Row, Button, Spinner } from "react-bootstrap";
import robotModel from "../../models/robot.model";

export default function Robots() {
  const [status, setStatus] = useState("DEFAULT");

  const [config, setConfigs] = useState({});
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    email: "nathanvasconcellos@hotmail.com",
    senha: "Na280491@",
    login: true,
  });

  async function init() {
    const data = await robotModel.alive();
    setStatus(data.status);
    setConfigs(data);
  }

  async function handlerReset() {
    init();
  }
  useEffect(() => {
    handlerReset();
  }, []);

  async function initRobot() {
    setLoading(true);
    await robotModel.initRobotDominio(form);
    await init();
    setLoading(false);
  }

  async function stopRobot() {
    setLoading(true);
    await robotModel.stopRobot();
    await init();
    setLoading(false);
  }

  async function initRelatorio() {
    setLoading(true);
    await robotModel.initReport();
    setLoading(false);
  }

  async function removeAnalised() {
    setLoading(true);
    await robotModel.removeAnalised();
    init();
    setLoading(false);
  }

  return (
    <>
      <h5>
        Status do robô:{" "}
        {status === "DEFAULT" ? "" : status ? "Funcionando" : "Desligado"}
      </h5>
      <br />
      <Form.Label htmlFor="inputPassword5">Acesso da Dominio:</Form.Label>
      <Row>
        <Col>
          <Form.Label htmlFor="inputPassword5">E-mail:</Form.Label>
          <Form.Control
            type="email"
            value={form.email}
            onChange={({ target }) => setForm({ ...form, email: target.value })}
          />
        </Col>
        <Col>
          <Form.Label htmlFor="inputPassword5">Senha:</Form.Label>
          <Form.Control
            type="password"
            value={form.senha}
            onChange={({ target }) => setForm({ ...form, senha: target.value })}
          />
          <Form.Check
            type="switch"
            id="custom-switch"
            label="Fazer Login"
            defaultChecked={form.login}
            onChange={() => setForm({ ...form, login: !form.login })}
          />
        </Col>
      </Row>
      <br />
      {config?.total ? (
        <Row>
          <span>Total de empresas: {config?.total}</span>
          <span>Já analisados: {config?.cache}</span>
          <span>Faltantes: {config?.total - config?.cache}</span>
          <span>Robô: {config?.active ? "Ativo" : "Inativo"}</span>
          <span>Status: {config?.msg ?? "-"}</span>
        </Row>
      ) : null}
      <br /> <br />
      <Row>
        <Col>
          <Button
            disabled={loading}
            onClick={() => (!config?.active ? initRobot() : stopRobot())}
          >
            {loading ? (
              <Spinner size="sm" animation="border" role="status" />
            ) : null}{" "}
            {!config?.active ? "Iniciar Robô" : "Parar Robô"}
          </Button>{" "}
          <Button disabled={loading} onClick={() => initRelatorio()}>
            {loading ? (
              <Spinner size="sm" animation="border" role="status" />
            ) : null}{" "}
            Gerar Relatório
          </Button>{" "}
          <Button disabled={loading} onClick={() => removeAnalised()}>
            {loading ? (
              <Spinner size="sm" animation="border" role="status" />
            ) : null}{" "}
            Limpar Analisados
          </Button>
        </Col>
      </Row>
    </>
  );
}
