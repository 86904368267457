/* eslint-disable no-const-assign */
import { useState, useEffect, useRef } from "react";
import "./style.css";
import { toast } from "react-hot-toast";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import Utils from "../../../helpers/utils";
import AuthService from "../../../services/auth";

export default function Conversor() {
  const [base64Pdf, setBase64Pdf] = useState(null);
  const [filename, setFilename] = useState(null);
  const [ofxResult, setOfxResult] = useState(null);
  const [isConverting, setIsConverting] = useState(false);
  const [selectedBank, setSelectedBank] = useState("btg");

  useEffect(() => {
    if (ofxResult == null) return;
    const blob = new Blob([ofxResult], { type: "application/octet-stream" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;

    if (filename.indexOf(".ofx") === -1) {
      filename += ".ofx";
    }

    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(url);
  }, [ofxResult]);

  const inputFileRef = useRef(null);

  const handleFile = async (event) => {
    const file = event.target.files[0];
    setFilename(file.name.replace(/\.pdf$/, ".ofx"));
    const base64 = await Utils.GetBase64(file);
    const base64String = base64.replace(/^data:application\/pdf;base64,/, "");
    setBase64Pdf(base64String);
  };

  const authService = new AuthService();
  const jwtAuth = authService.getToken();

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${jwtAuth}`);

  function convertToOfx(payload) {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(payload),
        redirect: "follow",
      };

      fetch(
        `https://e77n81fbgf.execute-api.us-east-1.amazonaws.com/convert_to_ofx/${selectedBank}`,
        // `http://localhost:5000/convert_to_ofx/${selectedBank}`,
        requestOptions
      )
        .then((response) => {
          if (response.status === 200) {
            resolve(response.text());
          } else {
            reject(
              new Error(
                `A solicitação falhou com o código de estado ${response.status}`
              )
            );
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  function downloadOfx() {
    setIsConverting(true);
    const payload = {
      pdf: base64Pdf,
      bank: selectedBank,
    };

    if (payload.pdf == null) {
      setIsConverting(false);
      return Promise.reject(new Error("No PDF file provided."));
    }

    return new Promise((resolve, reject) => {
      toast
        .promise(convertToOfx(payload), {
          loading: "Convertendo...",
          success: <b>Arquivo OFX gerado com sucesso!</b>,
          error: (
            <b>
              Houve um erro na conversão para OFX, verifique o arquivo e tente
              novamente.
            </b>
          ),
        })
        .then((result) => {
          setOfxResult(result);
          setIsConverting(false);
          resolve(result);
        })
        .catch((error) => {
          setIsConverting(false);
          reject(error);
        })
        .finally(() => {
          setBase64Pdf(null);
          setFilename(null);
          setOfxResult(null);
          setIsConverting(null);
          inputFileRef.current.value = null; // Reset the input file value
        });
    });
  }

  return (
    <>
      <div className="bi-header-div">
        <span className="h5 bi-header">
          Conversor de extrato (PDF) para OFX{" "}
        </span>
        <span className="bi-header-description">
          Converta um PDF para o formato OFX.
        </span>
      </div>
      <Form className="bi-form-data">
        <Row>
          <Col md={12}>
            <Form.Group className="mb-3">
              <Form.Label>Banco:</Form.Label>
              <Form.Select
                className="form-bi"
                value={selectedBank}
                onChange={(event) => setSelectedBank(event.target.value)}
              >
                <option value="bb">Banco do Brasil</option>
                <option value="btg" selected>
                  BTG Pactual
                </option>
                <option value="bradesco">Bradesco</option>
                <option value="cora">Cora</option>
                <option value="inter">Inter</option>
                <option value="nubank">Nubank</option>
                <option value="sicoob">Sicoob</option>
                <option value="sicred">Sicred</option>
                <option value="itau">Itaú</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Arquivo PDF de extrato:</Form.Label>
              <Form.Control
                type="file"
                accept=".pdf"
                ref={inputFileRef}
                onChange={handleFile}
              />
            </Form.Group>
          </Col>
        </Row>
        <Button
          className="login-btn"
          primary
          color="#025DFD"
          disabled={!base64Pdf || isConverting}
          onClick={() => downloadOfx()}
        >
          {isConverting ? (
            <>
              <Spinner
                animation="border"
                role="status"
                style={{ width: 24, height: 24 }}
              />
              <span>Convertendo</span>
            </>
          ) : (
            <span>Converter</span>
          )}
        </Button>
      </Form>
    </>
  );
}
